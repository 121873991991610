import React, { useState, useEffect } from "react";
import "./Cookies.css";

const Cookies = () => {
  const [isVisible, setIsVisible] = useState(true); // Výchozí viditelnost nastavená na true

  useEffect(() => {
    // Zkontroluje, zda byl souhlas již odeslán do dataLayer
    if (window.dataLayer) {
      const consentGiven = window.dataLayer.find((event) =>
        event.event === "cookie_consent"
      );
      if (consentGiven) {
        setIsVisible(false); // Pokud již existuje, skryje banner
      }
    }
  }, []);

  const handleAccept = () => {
    console.log("Accept button clicked");
    setIsVisible(false);

    // Odeslání události do dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "cookie_consent",
      consent_status: "accepted",
    });
  };

  const handleDecline = () => {
    console.log("Decline button clicked");
    setIsVisible(false);

    // Odeslání události do dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "cookie_consent",
      consent_status: "declined",
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="Cookies">
      <div className="Cookies-Content">
        <p className="Cookies-Content-title1">
          ITaborsky.cz používá soubory cookies pro zlepšení Vašeho zážitku.
        </p>
        <p className="Cookies-Content-title2">Souhlasíte s jejich použitím?</p>
        <div className="Cookies-Butts">
          <button className="Cookies-Decline" onClick={handleDecline}>
            Nesouhlasím
          </button>
          <button className="Cookies-Accept" onClick={handleAccept}>
            Souhlasím
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
